import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
// @mui
import { Button, Container, Grid, Stack, Typography } from "@mui/material";

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// components
import ReactSpeedometer from "react-d3-speedometer";
// sections
import { AppWidgetSummary } from "../sections/@dashboard/app";

import { isLoading, openSnackbar } from "../redux/action/defaultActions";
import { baseUrl } from "../utils/apiUrl";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [dashboardCardData, setDashboardCardData] = useState(null);
  const [co2Savings, setCo2Savings] = useState(0);
  const [singleUseCupCost, setSingleUseCupCost] = useState(0);
  const [wasteToLandfill, setWasteToLandfill] = useState(0);
  const [minMaxValues, setMinMaxValues] = useState({
    minValue: 0,
    maxValue: 100,
  });
  const [singleUseCupCostMinMax, setSingleUseCupCostMinMax] = useState({
    minValue: 0,
    maxValue: 100,
  });
  const [wasteToLandfillMinMax, setWasteToLandfillMinMax] = useState({
    minValue: 0,
    maxValue: 1,
  });

  useEffect(() => {
    const getDashboardCardData = async () => {
      dispatch(isLoading(true));
      try {
        const res = await axios.get(`${baseUrl}/store/dashboard/all/details`);

        setDashboardCardData(res?.data?.vendorData);
        setSingleUseCupCost(res?.data?.vendorData?.singleUseCupCost || 0);
        setWasteToLandfill(res?.data?.vendorData?.wasteToLandfill || 0);
        dispatch(isLoading(false));
      } catch (error) {
        if (error.response.data.message) {
          dispatch(openSnackbar(error.response.data.message, "error"));
        } else {
          dispatch(openSnackbar("something went wrong", "error"));
        }
        dispatch(isLoading(false));
      }
    };
    getDashboardCardData();
  }, [dispatch]);

  useEffect(() => {
    if (dashboardCardData?.co2Savings) {
      setCo2Savings(dashboardCardData.co2Savings);
    }
  }, [dashboardCardData]);

  useEffect(() => {
    const calculateMinMaxValue = (value) => {
      const maxValue = Math.ceil(value / 100) * 100;
      const minValue = maxValue - 100;
      return { minValue, maxValue };
    };

    if (co2Savings > 0) {
      setMinMaxValues(calculateMinMaxValue(co2Savings));
    }
  }, [co2Savings]);

  useEffect(() => {
    const calculateMinMaxValue = (value) => {
      const maxValue = Math.ceil(value / 100) * 100;
      const minValue = maxValue - 100;
      return { minValue, maxValue };
    };

    if (singleUseCupCost > 0) {
      setSingleUseCupCostMinMax(calculateMinMaxValue(singleUseCupCost));
    }
  }, [singleUseCupCost]);

  useEffect(() => {
    const calculateMinMaxValue = (value) => {
      const maxValue = Math.ceil(value / 1) * 1;
      const minValue = maxValue - 1;
      return { minValue, maxValue };
    };

    if (wasteToLandfill > 0) {
      setWasteToLandfillMinMax(calculateMinMaxValue(wasteToLandfill));
    }
  }, [wasteToLandfill]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Vendor App </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        {/* {dashboardCardData ? ( */}
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            // onClick={() => {
            //   navigate("/cups");
            // }}
          >
            <AppWidgetSummary
              title={"Total Cups"}
              total={dashboardCardData?.cups ? dashboardCardData?.cups : 0}
              icon={"game-icons:coffee-mug"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            onClick={() => {
              navigate("/all/users");
            }}
          >
            <AppWidgetSummary
              title="All Users"
              total={
                dashboardCardData?.customers ? dashboardCardData?.customers : 0
              }
              color="info"
              icon={"raphael:users"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            // onClick={() => {
            //   navigate("/cups");
            // }}
          >
            <AppWidgetSummary
              title="Cup Orders"
              total={dashboardCardData?.orders ? dashboardCardData?.orders : 0}
              color="warning"
              icon={"emojione-monotone:beer-mug"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              transition: "ease-in all 300ms",
              cursor: "pointer",
              "&:hover": { scale: "1.075" },
            }}
            // onClick={() => {
            //   navigate("/cups");
            // }}
          >
            <AppWidgetSummary
              title="Transanctions"
              total={
                dashboardCardData?.cupRequests
                  ? dashboardCardData?.cupRequests
                  : 0
              }
              color="error"
              icon={"tdesign:undertake-transaction"}
            />
          </Grid>
        </Grid>
        {/* ) : (
          <Typography variant="body2" color={"error"} component={"p"}>
            There are no transactions found for your store! Start Transactions ,
            made customers to visit your store
          </Typography>
        )
        } */}
        <Stack mt={2}>
          <Typography variant="h6">Quick Links</Typography>
        </Stack>
        <Stack mt={1} direction={"row"} spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/recieve")}
          >
            Recieve Cup
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/place/order")}
          >
            Place Order
          </Button>
        </Stack>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={4}>
            <div
              style={{
                width: "100%",
                height: "300px",
              }}
            >
              <ReactSpeedometer
                minValue={minMaxValues.minValue}
                maxValue={minMaxValues.maxValue}
                value={co2Savings}
                needleColor="red"
                startColor="green"
                segments={10}
                endColor="blue"
                textColor="black"
                forceRender
                valueTextFontSize="1.2rem"
                valueTextStyle={{ marginTop: "20px" }}
                currentValueText={`${co2Savings} Kg CO2e`}
                paddingVertical={15}
                fluidWidth
              />
            </div>
            <Typography variant="h6" align="center" sx={{ mt: 2 }}>
              CO2 Savings
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              style={{
                width: "100%",
                height: "300px",
              }}
            >
              <ReactSpeedometer
                minValue={singleUseCupCostMinMax.minValue}
                maxValue={singleUseCupCostMinMax.maxValue}
                value={singleUseCupCost}
                needleColor="red"
                startColor="green"
                segments={10}
                endColor="blue"
                textColor="black"
                forceRender
                valueTextFontSize="1.2rem"
                valueTextStyle={{ marginTop: "20px" }}
                currentValueText={`${singleUseCupCost.toFixed(2)} RM`}
                paddingVertical={15}
                fluidWidth
              />
            </div>
            <Typography variant="h6" align="center" sx={{ mt: 2 }}>
              Cost Savings
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              style={{
                width: "100%",
                height: "300px",
              }}
            >
              <ReactSpeedometer
                minValue={wasteToLandfillMinMax.minValue}
                maxValue={wasteToLandfillMinMax.maxValue}
                value={wasteToLandfill}
                needleColor="red"
                startColor="green"
                segments={10}
                endColor="blue"
                textColor="black"
                forceRender
                valueTextFontSize="1.2rem"
                valueTextStyle={{ marginTop: "20px" }}
                currentValueText={`${wasteToLandfill} Kg`}
                paddingVertical={15}
                fluidWidth
              />
            </div>
            <Typography variant="h6" align="center" sx={{ mt: 2 }}>
              Waste to Landfill
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
